<template>
    <div class="basic-page-components">
        <div class="page-wapper">
			<div class="page-main">
				<div class="shadow-content">
					<svg width="734" height="678" viewBox="0 0 734 678" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.6" filter="url(#filter0_f)">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M591.209 352.001C580.173 386.669 554.914 413.19 532.037 441.462C512.326 465.821 491.32 487.535 466.292 506.373C438.414 527.356 412.122 556.558 377.273 557.946C342.311 559.339 312.951 533.407 284.067 513.634C256.911 495.044 237.575 469.427 214.166 446.286C182.126 414.612 126.833 396.642 120.566 352.001C114.507 308.845 158.489 275.35 185.353 241.061C209.162 210.671 235.738 183.755 268.438 163.262C302.015 142.219 338.082 115.019 377.273 120.785C416.401 126.54 433.038 176.499 469.088 192.779C511.116 211.758 573.755 185.58 601.836 222.192C628.703 257.222 604.604 309.919 591.209 352.001Z"
								fill="#82BBFF" />
						</g>
						<defs>
							<filter id="filter0_f" x="0" y="0" width="734" height="678" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
								<feGaussianBlur stdDeviation="60" result="effect1_foregroundBlur" />
							</filter>
						</defs>
					</svg>
				</div>
				<div class="shadow-content-1">
					<svg width="900" height="826" viewBox="0 0 900 826" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.5" filter="url(#filter0_f_1)">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M749.55 430.394C734.806 476.776 701.06 512.259 670.495 550.084C644.16 582.674 616.096 611.725 582.657 636.928C545.411 665.001 510.285 704.07 463.725 705.928C417.015 707.792 377.788 673.097 339.199 646.643C302.918 621.771 277.084 587.499 245.808 556.537C203.003 514.162 129.13 490.119 120.756 430.394C112.662 372.656 171.422 327.842 207.314 281.967C239.124 241.309 274.63 205.297 318.317 177.88C363.177 149.727 411.364 113.336 463.725 121.05C516.001 128.75 538.229 195.59 586.393 217.371C642.544 242.762 726.231 207.739 763.748 256.722C799.644 303.589 767.447 374.093 749.55 430.394Z"
								fill="#9AE8D8" />
						</g>
						<defs>
							<filter id="filter0_f_1" x="0" y="0" width="900" height="826" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
								<feGaussianBlur stdDeviation="60" result="effect1_foregroundBlur" />
							</filter>
						</defs>
					</svg>
				</div>
				<div class="image-content">
					<slot name="left"></slot>
				</div>
				<div class="page-content">
					<slot name="right"></slot>
				</div>
			</div>
		</div>
		<div class="page-footer">
			<span>Copyright © 2020-{{ new Date().getFullYear() }} LanguageX. All Rights Reserved.</span>
			<span style="margin-left: 15px"><a href="https://beian.miit.gov.cn" target="_blank">京ICP备17005664号</a></span>
			<span style="margin-left: 15px"><a href="http://static.languagex.com/page/privacyPolicy.html" target="_blank">{{ $t('system.login_page_privacy_policy') }}</a></span>
			<span style="margin-left: 15px"><a href="http://static.languagex.com/page/serviceTerms.html" target="_blank">{{$t('system.login_page_service_terms')}}</a></span>
		</div>
    </div>
</template>
<script>
export default {
    data(){
        return {}
    }
}
</script>
<style lang="scss" scoped>
.basic-page-components {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #eef3fa;
	background-size: 100%;
	position: relative;
	.page-wapper {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.page-footer {
		height: 40px;
		display: flex;
		align-items: center;
		z-index: 900;
		font-size: 13px;
		a {
			color: #000;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
	.page-main {
		width: 840px;
		height: 460px;

		position: relative;
	}
	.shadow-content {
		position: absolute;
		top: -130px;
		left: 0;
	}
	.shadow-content-1 {
		position: absolute;
		top: -100px;
		left: 0px;
	}
	.image-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		width: 460px;
		height: 460px;
		background: #e7eef5;
		border: 2px solid rgba(255, 255, 255, 0.2);
		box-sizing: border-box;
		box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
			-9px -9px 16px rgba(255, 255, 255, 0.6);
		border-radius: 50%;
	}
	.page-content {
		position: absolute;
		right: 0;
		width: 460px;
		height: 460px;
		background: #e7eef5;
		border: 2px solid rgba(255, 255, 255, 0.2);
		box-sizing: border-box;
		box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
			-9px -9px 16px rgba(255, 255, 255, 0.6);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		::v-deep .show-password {
			position: absolute;
			top: 0;
			right: 10px;
		}
		.subtitle {
			color: #000000;
			text-align: center;
			font-weight: 300;
			font-size: 24px;
			// line-height: 34px;
			margin-bottom: 30px;
			> img {
				width: 88px;
				height: 55px;
			}
		}
		.form-content {
			width: 320px;
			display: flex;
			align-items: center;
			flex-direction: column;
		}
        ::v-deep .el-input{
            border-radius: 20px;
            box-shadow: inset -3px -3px 7px #ffffff,
                    inset 3px 3px 7px rgba(156, 156, 156, 0.48);
            .el-input__inner {
                background-color: transparent;
                border-radius: 20px;
                color: #000;
            }
            .el-input__inner:-webkit-autofill {
                box-shadow: 0 0 0 1000px transparent inset !important;
                -webkit-text-fill-color: #fff;
            }
            .el-input__inner:-internal-autofill-previewed,
            .el-input__inner:-internal-autofill-selected {
                -webkit-text-fill-color: #333 !important;
                transition: background-color 5000s ease-in-out 0s !important;
            }
            &.el-input-group {
                .el-input__inner {
                    box-shadow: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-color: transparent;
                    &:focus {
                        border-color: #000;
                    }
                }
                .el-input-group__prepend {
                    background-color: transparent;
                    border-color: transparent;
                    color: #000;
                    .el-input {
                        box-shadow:none;
                        .el-input__inner {
                            border-color: transparent;
                            box-shadow: none;
                            border-radius: 20px;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
                
            }
        }
        ::v-deep .el-button.is-round {
            padding: 8px 33px;
            line-height: 24px;
        }
        .qrcode-content{
            width: 160px;
            height: 160px;
            box-sizing: border-box;
            background-color: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            position: relative;
            // padding: 9.6px;
            img{
                border-radius: 10px;
                width: 100%;
                height: 100%;
            }
            .code-expired{
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.8);
                backdrop-filter: blur(3.5px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
            }
        }
	}
}
</style>
