<template>
    <div class="basic-page-left-component">
        <div class="logo-image">
            <a href="https://www.languagex.com" target="_blank">
                <img src="../../../../static/images/logo-login.png" />
            </a>
            <div class="subtitle">{{$t('system.slogan')}}</div>
        </div>
        <div class="description-list">
            <div class="item">
                <svg-icon name="ib-quan-o"></svg-icon>
                <div class="flex-1">{{$t('system.basic_left_tagline_1')}}</div>
            </div>
            <div class="item">
                <svg-icon name="ib-quan-o"></svg-icon>
                <div class="flex-1">{{$t('system.basic_left_tagline_2')}}</div>
            </div>
            <div class="item">
                <svg-icon name="ib-quan-o"></svg-icon>
                <div class="flex-1">{{$t('system.basic_left_tagline_3')}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BasicPageLeft",
}
</script>

<style lang="scss" scoped>
.basic-page-left-component{
    > .logo-image {
			text-align: center;
			img {
				width: 194px;
				height: 26px;
			}
			> .subtitle {
				font-weight: 300;
				font-size: 14px;
				line-height: 20px;
				color: #000000;
				margin-top: 9.67px;
			}
		}
		> .description-list {
			height: 124px;
            max-width: 275px;
			margin-top: 30px;
			> .item {
                display: flex;
                // align-items: center;
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 16px;
				color: #00000099;
				> svg {
					font-size: 15px;
					color: #7dd6b3;
                    margin-top: 2px;
                    margin-right: 3px;
				}
			}
		}
}
</style>
